<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Нас с вами ждет очень творческий и насыщенный процесс создания костюма начала XIX века в стиле ампир.</p>
            <p>Все необходимые материалы можно <a target=”_blank” href="https://docs.google.com/document/d/1X1un_-ALesvIrrVVxEmOrlEzzROwtAre5XtLnSPL0CY/edit?usp=sharing"> найти тут</a></p>
            
            <p>У вас есть время, чтобы определиться с образом и цветотипом вашей куколки. В помощь выкладываю вам урок из марафона по росписи, который отлично поддерживает тему костюма и образа. Смотреть до 20-ой минуты.</p>
            <p class="nomargin"><b>С чем необходимо определиться до начала:</b></p>
            <ol>
                <li><a target=”_blank” href="https://color.romanuke.com/">Цветовая схема костюма</a>  (главный цвет и второстепенные)</li>
                <li>Составить коллаж из тканей, кружав, соединив всё в единую картину вместе с куклой )</li>
            </ol>
        </div>

        <VideoView video-id="d9cdb23f53264e349b4746fae85ce433"/>
       
        <div class="MBtextcont">
            <p>Небольшой гайд о том, как выбирать ткани</p>
        </div>

         <VideoView video-id="933740148ea44efebd3da6d0e65586d2"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>